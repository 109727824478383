













































































































































































































































































@use "variables" as *;

.dropzone {
	display          : flex;
	height           : 100px;
	background-color : $white;
	border           : 2px dashed $brand;
	align-items      : center;
	flex-direction   : column;
	justify-content  : center;

	&:hover,
	&.dragging {
		background-color : $sky;
	}

	&.dragging {
		.dropzone-title {
			font-weight : bold;
		}
	}

	&.error {
		border-color : $red;
	}
}

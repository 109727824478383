













































@use "variables" as *;

.plaintext {
	> .value {
		padding : 0.125rem 0.5rem;
	}
}

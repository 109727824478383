











































.viewport-dimensions {
	position   : absolute;
	top        : 0;
	left       : 0;
	padding    : 0.5rem;
	background : white;
	color      : black;
	opacity    : 0.75;
	z-index    : 3000;
}

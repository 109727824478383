@import "variables";

.ticket-form-trigger {
	position         : fixed;
	right            : 20px;
	bottom           : 20px;
	padding          : 10px 20px;
	background-color : $secondary;
	color            : $white;
	border-radius    : 30px;
	font-weight      : bold;
	cursor           : pointer;
	z-index          : 1030;

	&.hidden {
		display : none;
	}
}

.ticket-container {
	position   : fixed;
	right      : 0;
	bottom     : 0;
	width      : 374px;
	height     : 810px;
	max-height : 100%;
	transition : transform ease 0.3s !important;
	padding    : 16px;
	z-index    : 1040;

	&.hidden {
		transform : translateY(150vh);
	}
}

.ticket-form {
	display          : flex;
	height           : 100%;
	background-color : $white;
	border-radius    : 5px;
	box-shadow       : 0 0 15px rgb(0 0 0 / 20%);
	flex-direction   : column;
	overflow         : hidden;

	.header {
		padding          : 16px;
		background-color : $secondary;
		color            : $white;
		font-weight      : bold;
		text-align       : center;

		.close-form {
			color       : $white;
			font-weight : bolder;
			cursor      : pointer;
			float       : right;
		}
	}

	.body {
		padding  : 16px;
		overflow : hidden auto;

		&::-webkit-scrollbar {
			width : 5px;
		}

		&::-webkit-scrollbar-track {
			background : $white;
		}

		&::-webkit-scrollbar-thumb {
			background : $gray-200;
		}

		label {
			font-weight : bold;
		}

		textarea {
			height : 125px !important;
			resize : none;
		}

		input[type="file"] {
			overflow-x : hidden;
		}

		input[type="text"],
		input[type="email"],
		input[type="file"],
		select,
		textarea {
			display       : block;
			width         : 100% !important;
			height        : auto !important;
			transition    : all ease 0.3s;
			margin        : 0 0 16px !important;
			padding       : 8px 16px !important;
			border        : $border-width solid $border-color;
			border-radius : 5px;
			font-size     : 16px;
			appearance    : none;

			&:focus {
				box-shadow : 0 0 5px rgb(0 0 0 / 40%);
			}
		}

		input[type="button"],
		input[type="submit"],
		button {
			padding       : 8px 16px !important;
			background    : $secondary;
			color         : $white;
			border        : none;
			border-radius : 5px;
			font-size     : 1em;

			&:disabled {
				opacity : 0.65;
			}
		}
	}

	.hs-submit {
		margin-top : 15px;
		text-align : right;
	}

	.hs-error-msgs {
		padding         : 0;
		list-style-type : none;

		.hs-error-msg {
			color     : $danger;
			font-size : 0.8em;
		}
	}
}

@media screen and (width <= 680px) {
	body.no-scroll {
		position : fixed;
		inset    : 0;
	}

	.ticket-container {
		width   : 100%;
		height  : 100%;
		margin  : 0;
		padding : 0;

		.ticket-form {
			border-radius : 0;
		}
	}
}

















































































































@use "variables" as *;

.form-group.warning .valid-feedback {
	display : block;
	color   : $warning;
}

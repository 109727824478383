














@use "variables" as *;

.blusky {
	transition       : opacity ease 0.3s;
	background-color : #b2dce4; // Doesn't follow our color palette. Extracted from the gif to look in phase with the gradient
	opacity          : 0;

	.animation-title,
	.animation-subtitle {
		color : $brand;
	}
}

.animation.visible {
	.blusky {
		opacity : 1;
	}
}

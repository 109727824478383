


















button {
	background  : transparent;
	box-shadow  : none;
	white-space : nowrap;
}


































































































































































// Remove button stylings added by the dropdown component from around the input
::v-deep {
	.input-dropdown {
		padding    : 0;
		background : none !important;
		border     : none !important;
		box-shadow : none;
	}

	.dropdown-item {
		overflow      : hidden;
		text-overflow : ellipsis;
	}
}


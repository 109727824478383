





































































.toggle-icon {
	transform  : rotate(0deg);
	transition : transform ease 0.35s;

	&.flip {
		transform : rotate(180deg);
	}
}






































































































































@use "variables" as *;

.dropdown {
	// make the dropdown button look like a b-form-select
	::v-deep .dropdown-toggle {
		background-color : $input-bg;
		color            : $input-color;
		border-color     : $input-border-color;
		text-align       : left;
		box-shadow       : $input-box-shadow;
		overflow         : hidden;

		&:disabled {
			background-color : $input-disabled-bg !important;
			pointer-events   : none;
		}
	}
}

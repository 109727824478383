








































































































































@use "variables" as *;

.animation {
	position       : fixed;
	top            : 0;
	left           : 0;
	width          : 100%;
	height         : 100%;
	transition     : all ease 0.3s;
	opacity        : 0;
	overflow       : scroll;
	pointer-events : none;
	z-index        : 1050;

	&.visible {
		opacity        : 1;
		pointer-events : auto;
	}
}














@use "variables" as *;

$duration: 3.2s;
$repeat : 1;
$animation-width: 150px;

.animation-success {
	transition : opacity ease 0.3s;
	opacity    : 0;
}

.animation.visible {
	.animation-success {
		width        : $animation-width;
		margin       : 2rem;
		animation    : rotate-box $duration $repeat forwards;
		aspect-ratio : 1.25;
		opacity      : 1;
		will-change  : transform;

		.front,
		.back {
			width         : 100%;
			height        : 100%;
			border-radius : $font-size-base;
			overflow      : hidden;
		}

		.front {
			position     : fixed;
			background   : $primary;
			stroke       : #fff;
			stroke-width : 3;

			.checkmark {
				transform         : rotate(-90deg);
				animation         : check-mark-animation $duration $repeat forwards;
				stroke-dasharray  : 48;
				stroke-dashoffset : 48;
				transform-origin  : 50% 50%;
			}
		}

		.back {
			position    : absolute;
			background  : $brand;
			animation   : move-back-box $duration $repeat forwards;
			will-change : transform;
			z-index     : -1;
		}
	}
}

@keyframes check-mark-animation {
	22% {
		stroke-dashoffset : 48;
	}

	45%,
	100% {
		stroke-dashoffset : 0;
	}
}

@keyframes rotate-box {
	0% {
		transform : rotate(0) translateX(0);
	}

	15.5%,
	18.5% {
		transform : rotate(90deg) translateX(-50%);
	}

	22% {
		transform : rotate(90deg) translateX(25%);
	}

	32% {
		transform                 : rotate(90deg) translateX(0);
		animation-timing-function : ease-out;
	}

	100% {
		transform : rotate(90deg) translateX(0);
	}
}

@keyframes move-back-box {
	0%,
	22% {
		transform : translate(0);
	}

	45% {
		transform                 : translate(-10%, -15%);
		animation-timing-function : ease-out;
	}

	100% {
		transform : translate(-10%, -15%);
	}
}




























































































































































@use "mixins" as *;

.resource-viewer {
	@include on-mobile() {
		.modal-body {
			padding : 0;
		}
	}

	iframe,
	video {
		width      : 100%;
		height     : 100%;
		border     : none;
		min-height : 500px;
	}
}

@media (min-height: 800px) {
	.resource-viewer {
		height: 100%;
	}
}
































































































@use "variables" as *;

.form-control[readonly]:not([disabled]) {
	background : $input-bg;
}

.month-picker::v-deep .popover-body {
	padding : 0;
}






























































































































































.spinner-wrapper {
	display         : flex;
	position        : absolute;
	top             : 0;
	right           : 0;
	bottom          : 0;
	left            : 0;
	margin          : 50px 0;
	align-items     : center;
	justify-content : center;
	z-index         : 1030;
}

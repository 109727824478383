



















































































@use 'variables' as *;
@use "mixins"    as *;

.variant-dark {
	position      : relative;
	margin        : 1rem 15px;
	padding       : 2rem;
	color         : $white;
	border        : 3px solid $white;
	border-radius : 15px;

	&::before {
		position         : absolute;
		top              : 50%;
		left             : -30px;
		width            : 60px;
		height           : 60px;
		transform        : translateY(-50%);
		background-color : $white;
		color            : $brand;
		border-radius    : 100%;
		font-family      : Georgia, 'Times New Roman', Times, serif;
		font-size        : 70px;
		font-weight      : bold;
		text-align       : center;
		content          : '“';
		line-height      : 90px;
	}
}

.rating-stars {
	color : $rating-color;
}





























































@use "mixins" as *;

.info-tip {
	> .b-icon {
		vertical-align : text-top;
	}
}

@media print {
	.info-tip {
		display : none;
	}
}

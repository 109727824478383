







































































































































































































































































































































































































































































































































































































































































































































































@use "variables" as *;
@use "mixins"    as *;

.fl-table {
	::v-deep {
		td,
		th {
			vertical-align : middle;
			white-space    : nowrap;
			word-wrap      : normal;

			// This is needed to vertical-align: middle the large switch
			.custom-switch.b-custom-control-lg {
				width : 0;

				.custom-control-label:empty {
					&::before {
						top : 2px;
					}

					&::after {
						top : 4px;
					}
				}
			}

			&.grouping {
				.group-toggle {
					transition : transform 0.3s ease;
				}

				.expanded {
					transform : rotate(180deg);
				}
			}
		}

		.b-table.b-table-selectable {
			tbody {
				tr {
					&.table-active,
					&:focus,
					&:active {
						background : $primary;
						color      : $white;

						td:hover {
							color : $white;
						}
					}
				}
			}
		}

		th.grouping {
			padding-inline : 0;

			.group-toggle {
				margin-left : 0.75rem;
			}
		}

		td.grouping {
			font-weight : bold;

			.group-toggle {
				margin-right : 0.75rem;
			}

			.error {
				color : $danger;
			}

			&:not(:empty) ~ td {
				display : none !important;	// hide any cells after the grouping cell
			}

			&:empty {
				width : 10px;	// left-padding for group rows

				@include on-mobile() {
					display : none;		// save limited space on mobile displays
				}
			}

			.group-count {
				margin-left   : 0.3em;
				padding       : 0 0.5em;
				border        : 1px solid $midnight;
				border-radius : 10px;
				font-size     : $font-size-sm;
			}
		}

		// SHOULDDO: these a pretty specific to the app; find a better place for this
		.stacked {
			&:focus {
				background : $primary;
				color      : $white;
			}

			.title {
				font-size   : $font-size-base;
				white-space : normal;
			}

			.description {
				font-size : $font-size-sm;
			}

			.status {
				text-transform : capitalize;
			}
		}

		table.table-hover tbody tr:hover {
			@include clickable-background-color($turquoise);
		}
	}
}




























































































































































@use "variables" as *;
@use "mixins"    as *;

.fl-steps-navigation {
	.buttons {
		gap : 1rem;

		.btn {
			margin-top : 0;

			@include on-mobile() {
				padding : 1rem;		// make the buttons on mobile a bit bigger to allow for better touch area
			}
		}
	}

	.dot {
		width            : 0.625rem;
		height           : 0.625rem;
		margin           : 0.375rem;
		padding          : 0;
		background-color : $gray-100;
		border-radius    : 100%;
		flex             : 0 0 auto;
	}
}

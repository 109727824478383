


















































@use "variables" as *;

.bottom-highlight {
	font-size : 1.5rem;

	&::after {
		display          : block;
		width            : 100px;
		height           : 2px;
		margin-top       : 0.5rem;
		background-color : $primary;
		content          : "";
	}
}




























@use "variables" as *;
@use "mixins"    as *;

.modal-body {
	overscroll-behavior-y : none;
}

.modal-dialog.fullscreen {
	@include on-mobile() {
		max-width  : none;
		max-height : 100%;
		margin     : 0;

		.modal-content {
			max-height    : 100%;
			border-radius : 0;
			overflow-y    : auto;
		}
	}
}









































































































@use "mixins"    as *;
@use "variables" as *;

.fl-tools {
	float   : right;

	// SHOULDDO: figure out a cleaner way of getting the drop-down menu to fly over all other things
	z-index : 1021; // 1021 = sticky-top + 1 (from bootstrap's Position utility)

	> svg {
		width   : 1.25rem;
		height  : 1.25rem;
		color   : #bf00bf;
		outline : none;

		@include clickable-spin(1.3);
	}
}

.fl-tools-popover {
	max-width  : 75vw;
	max-height : 75vh;
	overflow-y : auto;

	.popover-body {
		display        : flex;
		flex-direction : column;
	}

	.secondary-level {
		display        : flex;
		max-width      : 75vw;
		max-height     : 75vh;
		background     : #eee;
		flex-direction : column;
		flex-wrap      : wrap;
	}

	.divider {
		margin           : 0.1rem 1rem;
		height           : 1px;
		background-color : #AAA;
	}
}

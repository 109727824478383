



















































































































































































































































































































































.address-editor.form-row legend {
	overflow      : hidden;
	text-overflow : ellipsis;
	white-space   : nowrap;
}


















































::v-deep .carousel-inner {
	align-items : start !important;
}












































@use 'variables' as *;

.page-header {
	z-index : $z-index-sticky + 1;
}

.spinner {
	position  : absolute;
	top       : 50%;
	left      : 50%;
	transform : translate(-50%, -50%);
}
